.text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
.clearfix {
  .clearfix();
}

.box(){
  display: ~'-ms-flexbox';  //IE10
  display: -moz-box;
  display: -webkit-box;
  display:~'-o-box';
  //display: ~'box';
}
.box{
  .box();
}
.box-align(@center:center){
  -ms-box-align: @center;
  -moz-box-align: @center;
  -webkit-box-align: @center;
  -o-box-align: @center;
  box-align:@center;
}
.box-align{
  .box-align();
}
.flex(@number:1){
  -moz-box-flex: @number;
  -webkit-box-flex: @number;
  -ms-flex: @number;  /**IE ¼æÈÝ´úÂë*/
  //box-flex:1;
  //box-flex:1;
  //flex: 1;
}
.flex{
  .flex();
}
