.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.box {
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-box;
  display: -o-box;
}
.box-align {
  -ms-box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -o-box-align: center;
  box-align: center;
}
.flex {
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  /**IE ¼æÈÝ´úÂë*/
}
.section-banner {
  height: 498px;
  position: relative;
}
.section-banner .banner-list {
  position: relative;
  height: 100%;
}
.section-banner .banner-list li {
  float: left;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.section-banner .prev,
.section-banner .next {
  position: absolute;
  cursor: pointer;
  top: 50%;
  z-index: 9999;
  width: 16px;
  height: 33px;
  background-image: url("../images/sprites_home.png");
  background-repeat: no-repeat;
  margin-top: -16px;
}
.section-banner .prev:hover,
.section-banner .next:hover {
  transition: all 0.2s ease-in;
  opacity: 0.5;
}
.section-banner .prev {
  left: 3.2rem;
  background-position: -143px -478px;
}
.section-banner .prev:hover {
  left: 3rem;
}
.section-banner .next {
  right: 3.2rem;
  background-position: -173px -478px;
}
.section-banner .next:hover {
  right: 3rem;
}
.section-banner .banner-caption {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  color: #FFFFFF;
  top: 0;
  left: 0;
}
.section-banner .banner-caption .inner {
  display: table-cell;
  vertical-align: middle;
  background: url("../images/banner_mask.png") center center repeat-x;
}
.section-banner .banner-caption h2 {
  font-size: 3.6rem;
  font-family: "open_sansbold";
  line-height: 5rem;
}
.section-banner .banner-caption p {
  font-size: 1.8rem;
  font-family: "open_sanssemibold";
  line-height: 3rem;
}
.section-banner .banner-caption .btn {
  margin-top: 8rem;
  height: 5.6rem;
  width: 19.2rem;
  background: #ffffff;
  font-family: "open_sansbold";
}
.section-banner .banner-caption .btn:hover {
  color: #FFFFFF;
  transition: all 0.1s ease-in;
  background: #f3aa0c;
}
.section-process {
  padding-top: 10rem;
  padding-bottom: 11.2rem;
  background: #FFFFFF;
}
.section-process .container {
  text-align: center;
}
.section-process h2 {
  font-size: 3.0rem;
  font-family: "open_sansbold";
  text-align: center;
}
.process-list {
  overflow: hidden;
  font-size: 0;
  padding-bottom: 6.4rem;
  padding-top: 7rem;
}
.process-list h3 {
  font-size: 2.0rem;
  font-family: "open_sanssemibold";
  padding-top: 2.4rem;
  line-height: 5rem;
}
.process-list h3 .icon {
  display: block;
  margin: 0 auto;
}
.process-list li {
  float: left;
  width: 20%;
  box-sizing: border-box;
  height: 28rem;
  vertical-align: top;
  text-align: center;
  border: solid 1px #FFFFFF;
  border-top: none;
  transition: all 0.2s ease-in;
  position: relative;
  line-height: 2.4rem;
  padding: 0 1.5rem;
}
.process-list li .text {
  font-size: 1.4rem;
  color: #bcbcbc;
}
.process-list li:last-child {
  margin-right: 0;
}
.process-list li:before {
  transition: all 0.2s ease-in;
  content: " ";
  position: absolute;
  left: -1px;
  width: 22.4rem;
  border-top: 4px solid #FFFFFF;
}
.process-list li:hover {
  border-color: #e5e5e5;
}
.process-list li:hover:before {
  width: 100%;
  border-top-color: #007bc7;
}
.process-operation {
  text-align: center;
  width: 53%;
  margin: 0 auto;
}
.process-operation .btn {
  display: block;
  /**IE ¼æÈÝ´úÂë*/
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  /**IE ¼æÈÝ´úÂë*/
  margin-right: 3.2rem;
  padding: 1.3rem 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.process-operation .btn:last-child {
  background: #004ea2;
}
.process-operation .btn:last-child:hover {
  background: #005dc1;
}
.process-operation .btn:last-child {
  margin-right: 0;
}
.section-professionals {
  padding-top: 10rem;
  padding-bottom: 11.2rem;
  background: #f6f6f6;
}
.section-professionals h2 {
  font-size: 3rem;
  text-align: center;
  font-family: "open_sansbold";
}
.section-professionals .professionals-list {
  width: 100%;
  font-size: 0;
  overflow: hidden;
}
.section-professionals .professionals-list li {
  float: left;
  width: 33.333%;
}
.section-professionals .professionals-list li:first-child {
  padding-right: 4%;
}
.section-professionals .professionals-list li:nth-child(2) {
  padding: 0 2%;
}
.section-professionals .professionals-list li:last-child {
  padding-left: 4%;
}
.section-professionals .professionals-list li:hover p {
  border-top-color: #007bc7;
}
.section-professionals .professionals-list li h3 {
  font-size: 2rem;
  line-height: 6rem;
  font-family: "open_sanssemibold";
}
.section-professionals .professionals-list li p {
  height: auto;
  min-height: 10.4rem;
  padding: 0.6rem 2.4rem;
  overflow: hidden;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #bcbcbc;
  background: #FFFFFF;
  transition: all 0.3s ease-in;
  border-top: solid 6px #d9d9d9;
}
.section-info {
  padding-top: 10rem;
  padding-bottom: 5rem;
  background: #FFFFFF;
}
.section-info h2 {
  font-size: 3.0rem;
  text-align: center;
  font-family: "open_sansbold";
  line-height: 4rem;
  margin-bottom: 5rem;
}
.section-info .article-list:before,
.section-info .article-list:after {
  content: " ";
  display: table;
}
.section-info .article-list:after {
  clear: both;
}
.section-info .article-list:before,
.section-info .article-list:after {
  content: " ";
  display: table;
}
.section-info .article-list:after {
  clear: both;
}
.section-info .article-list dl {
  float: left;
  height: auto;
  width: 33.333%;
}
.section-info .article-list dl:nth-child(3n+1) {
  padding: 0 2rem 5rem 0;
  clear: left;
}
.section-info .article-list dl:nth-child(3n+2) {
  padding: 0 1rem 5rem 0;
}
.section-info .article-list dl:nth-child(3n+3) {
  padding: 0  0 5rem 2rem;
  clear: right;
}
.section-info .article-list dt {
  font-size: 2rem;
  line-height: 4.8rem;
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 3rem;
  font-family: 'open_semibold';
  font-weight: bold;
}
.section-info .article-list .type-1 dd {
  margin-bottom: 4rem;
}
.section-info .article-list .type-1 dd:before,
.section-info .article-list .type-1 dd:after {
  content: " ";
  display: table;
}
.section-info .article-list .type-1 dd:after {
  clear: both;
}
.section-info .article-list .type-1 dd:before,
.section-info .article-list .type-1 dd:after {
  content: " ";
  display: table;
}
.section-info .article-list .type-1 dd:after {
  clear: both;
}
.section-info .article-list .type-1 dd:last-child {
  margin-bottom: 0;
}
.section-info .article-list .type-1 dd h3 {
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  font-weight: normal;
  overflow: hidden;
}
.section-info .article-list .type-1 dd h3 a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-info .article-list .type-1 dd h3 a:link,
.section-info .article-list .type-1 dd h3 a:visited {
  color: #2d2d2d;
}
.section-info .article-list .type-1 dd h3 a:hover {
  color: #007bc7;
}
.section-info .article-list .type-1 dd .pic {
  margin-right: 1rem;
  width: 112px;
  height: auto;
}
.section-info .article-list .type-1 dd .pic a {
  display: block;
}
.section-info .article-list .type-1 dd .pic a img {
  display: block;
  width: 100%;
  height: auto;
}
.section-info .article-list .type-1 dd p {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #bcbcbc;
  height: 73px;
  overflow: hidden;
}
.section-info .article-list .type-1 .price {
  display: block;
  font-size: 2.4rem;
  color: #007bc7;
}
.section-info .article-list .type-2 dd {
  width: 100%;
  margin-bottom: 2rem;
}
.section-info .article-list .type-2 dd:before,
.section-info .article-list .type-2 dd:after {
  content: " ";
  display: table;
}
.section-info .article-list .type-2 dd:after {
  clear: both;
}
.section-info .article-list .type-2 dd:before,
.section-info .article-list .type-2 dd:after {
  content: " ";
  display: table;
}
.section-info .article-list .type-2 dd:after {
  clear: both;
}
.section-info .article-list .type-2 dd .pic {
  margin-right: 1.5rem;
  width: 8.4rem;
  height: 8.4rem;
  border-radius: 50%;
}
.section-info .article-list .type-2 dd .pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.section-info .article-list .type-2 dd > div.pic-right {
  /**IE ¼æÈÝ´úÂë*/
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  /**IE ¼æÈÝ´úÂë*/
}
.section-info .article-list .type-2 dd h3 {
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  font-weight: normal;
}
.section-info .article-list .type-2 dd h3 a:link,
.section-info .article-list .type-2 dd h3 a:visited {
  color: #2d2d2d;
}
.section-info .article-list .type-2 dd h3 a:hover {
  color: #007bc7;
}
.section-info .article-list .type-2 dd p {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #bcbcbc;
  height: 6rem;
  overflow: hidden;
}
.section-info .article-list .type-3 dd {
  position: relative;
  padding-right: 2.5rem;
  margin-bottom: 4rem;
}
.section-info .article-list .type-3 dd:before,
.section-info .article-list .type-3 dd:after {
  content: " ";
  display: table;
}
.section-info .article-list .type-3 dd:after {
  clear: both;
}
.section-info .article-list .type-3 dd:before,
.section-info .article-list .type-3 dd:after {
  content: " ";
  display: table;
}
.section-info .article-list .type-3 dd:after {
  clear: both;
}
.section-info .article-list .type-3 dd:last-child {
  margin-bottom: 0;
}
.section-info .article-list .type-3 dd .pic {
  margin-right: 1rem;
  width: 160px;
  height: auto;
}
.section-info .article-list .type-3 dd .pic a {
  display: block;
}
.section-info .article-list .type-3 dd .pic a img {
  display: block;
  width: 100%;
  height: auto;
}
.section-info .article-list .type-3 dd h3 {
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  font-weight: normal;
  max-height: 4.8rem;
  overflow: hidden;
}
.section-info .article-list .type-3 dd h3 a:link,
.section-info .article-list .type-3 dd h3 a:visited {
  color: #2d2d2d;
}
.section-info .article-list .type-3 dd h3 a:hover {
  color: #007bc7;
}
.section-info .article-list .type-3 dd p {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #bcbcbc;
  max-height: 4rem;
  overflow: hidden;
}
.section-info .article-list .type-3 dd .price {
  font-size: 2.4rem;
  color: #007bc7;
  position: absolute;
  left: 12.2rem;
  bottom: 0;
}
.section-info .article-list .type-4 {
  height: auto;
}
.section-info .article-list .type-4 dd {
  height: 6.6rem;
  border-left: solid 0.6rem #d9d9d9;
  display: table;
  width: 100%;
  margin-bottom: 0.8rem;
}
.section-info .article-list .type-4 dd:hover {
  transition: all 0.3s ease-in;
  border-left-color: #007bc7;
}
.section-info .article-list .type-4 dd:hover a {
  border-top-color: #d9d9d9;
  border-bottom-color: #d9d9d9;
  border-right-color: #d9d9d9;
}
.section-info .article-list .type-4 dd a {
  padding-left: 0.8rem;
  padding-right: 2rem;
  border-top: solid 1px #FFFFFF;
  border-bottom: solid 1px #FFFFFF;
  border-right: solid 1px #FFFFFF;
  display: block;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.section-info .article-list .type-5 dd {
  display: table;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
  width: 100%;
}
.section-info .article-list .type-5 dd a {
  display: table-cell;
  position: relative;
  height: 6.6rem;
  vertical-align: middle;
  background: #f6f6f6;
  padding-left: 1rem;
  padding-right: 4.5rem;
}
.section-info .article-list .type-5 dd a:hover {
  transition: all 0.2s ease-in;
  background: #007bc7;
  color: #FFFFFF;
}
.section-info .article-list .type-5 dd a:after {
  content: "+";
  font-size: 2.4rem;
  line-height: 2.4rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -1.2rem;
}
.section-info .article-list .type-6 dd {
  display: table;
  width: 100%;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
}
.section-info .article-list .type-6 dd a {
  display: table-cell;
  position: relative;
  padding-left: 2rem;
  font-size: 1.4rem;
  height: 6.6rem;
  vertical-align: middle;
  border-bottom: solid 1px #e5e5e5;
}
.section-info .article-list .type-6 dd a:before {
  content: "•";
  font-size: 3rem;
  line-height: 3rem;
  position: absolute;
  left: 0;
  top: 0.6rem;
  color: #007bc7;
}
.section-info .article-list .type-6 dd a:hover:before {
  transition: all 0.2s ease-in;
  color: #bcbcbc;
}
.section-communities {
  background: #f6f6f6;
  padding-bottom: 9.6rem;
}
.section-communities h2 {
  font-weight: bold;
  font-size: 3rem;
  padding-top: 10rem;
  padding-bottom: 7rem;
  text-align: center;
  line-height: 4rem;
}
.section-communities .communities-list {
  font-size: 0;
  text-align: center;
}
.section-communities .communities-list li {
  float: left;
  position: relative;
  width: 25%;
  height: 15rem;
  cursor: pointer;
  padding: 0.8rem;
  text-align: center;
}
.section-communities .communities-list li .li-cont {
  position: relative;
  height: 14rem;
}
.section-communities .communities-list li .content {
  font-size: 1.4rem;
  opacity: 0;
  position: absolute;
  z-index: 99;
  background: #FFFFFF;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px #007bc7;
  transition: all 0.2s ease-in;
  display: table;
}
.section-communities .communities-list li .content .content-inner {
  padding: 0 38px;
  line-height: 2.4rem;
  display: table-cell;
  vertical-align: middle;
  color: #bcbcbc;
}
.section-communities .communities-list li h3 {
  height: 100%;
  padding-top: 2rem;
  font-size: 1.6rem;
  font-family: "open_sanssemibold";
  background-color: #FFFFFF;
}
.section-communities .communities-list li h3 .icon {
  display: block;
  margin: 0 auto;
}
.section-communities .communities-list li h3 span {
  display: block;
  line-height: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-communities .communities-list li:hover .content {
  opacity: 1;
}
@media (max-width: 1024px) {
  .process-list li {
    width: 33.333%;
  }
  .process-list li:nth-child(4) {
    margin-left: 16.666%;
  }
  .process-operation {
    width: 70%;
  }
  .process-operation .btn {
    margin-right: 15px;
    font-size: 1.8rem;
  }
  .section-professionals .professionals-list {
    display: block;
  }
  .section-info .article-list dl {
    width: 50%;
  }
  .section-info .article-list dl:nth-child(2n+1) {
    padding: 0 1.5rem 5rem 0;
    clear: left;
  }
  .section-info .article-list dl:nth-child(2n+2) {
    padding: 0 0 5rem 1.5rem;
    clear: right;
  }
}
@media (max-width: 900px) {
  .section-communities .communities-list li {
    width: 33.333%;
  }
  .process-list li {
    width: 50%;
  }
  .process-list li:nth-child(4) {
    margin-left: 0;
  }
  .process-list li:nth-child(5) {
    margin-left: 25%;
  }
}
@media (max-width: 800px) {
  .professionals-list {
    display: block;
  }
  .professionals-list li {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .communities-list li {
    width: 50%;
  }
}
@media (max-width: 640px) {
  .section-process h2 {
    font-size: 2rem;
  }
  .process-list li {
    width: 100%;
  }
  .process-list li:nth-child(4) {
    margin-left: 0;
  }
  .process-list li:nth-child(5) {
    margin-left: 0;
  }
  .process-operation {
    width: 100%;
    display: block;
  }
  .process-operation .btn {
    font-size: 1.6rem;
    width: 100%;
    max-width: 33.6rem;
    clear: both;
    margin: 0 auto 2rem;
  }
  .process-operation .btn:last-child {
    margin: 0 auto 2rem;
  }
  .section-professionals h2 {
    font-size: 2.5rem;
  }
  .section-professionals .professionals-list li {
    padding: 0 1rem;
    width: 100%;
  }
  .section-professionals .professionals-list li:nth-child(1),
  .section-professionals .professionals-list li:nth-child(2),
  .section-professionals .professionals-list li:nth-child(3) {
    padding: 0;
  }
  .section-info .article-list dl {
    width: 100%;
    margin-bottom: 2rem;
  }
  .section-info .article-list dl:nth-child(2n) {
    padding: 0 0 0 0;
  }
  .section-info .article-list dl:nth-child(2n+1) {
    padding: 0 0 0 0;
  }
  .section-communities .communities-list li {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .section-communities h2 {
    font-size: 2.6rem;
  }
  .communities-list li {
    width: 100%;
  }
  .section-professionals h2 {
    font-size: 2rem;
  }
  .section-professionals .professionals-list {
    display: block;
  }
}
@media (max-width: 450px) {
  .section-process h2 {
    font-size: 1.8rem;
  }
  .section-communities h2 {
    font-size: 2.2rem;
  }
}
@media (max-width: 400px) {
  .section-professionals h2 {
    font-size: 2rem;
  }
  .section-communities h2 {
    font-size: 2rem;
  }
  .section-communities .communities-list li {
    width: 100%;
  }
}
