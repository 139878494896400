@import "../../../common/styles/_util/variables";
@import "../../../common/styles/_util/mixins";
@media (max-width: 1024px){  //ipad mini
  .process-list {
    li{
      width: 33.333%;
      &:nth-child(4){
        margin-left: 16.666%;
      }
    }
  }
  .process-operation {
    width: 70%;
    .btn{
      margin-right: 15px;
      font-size: 1.8rem;
    }
  }
  .section-professionals .professionals-list{
    display: block;
  }
  .section-info{
    .article-list{
      dl{
        width: 50%;
        &:nth-child(2n+1){
          padding: 0 1.5rem 5rem 0;
          clear: left;
        }
        &:nth-child(2n+2){
          padding: 0 0 5rem 1.5rem;
          clear: right;
        }


      }
    }
  }
}
@media (max-width: 900px) {
  .section-communities .communities-list{
    li{
      width: 33.333%;
    }
  }
  .process-list {
    li{
      width: 50%;
      &:nth-child(4){
        margin-left: 0;
      }
      &:nth-child(5){
        margin-left: 25%;
      }
    }
  }
}
@media (max-width: 800px) {
  .professionals-list{
    display: block;
    li{
      width: 100%;
    }
  }
}
@media (max-width: 700px){
  .communities-list{
    li{
      width: 50%;
    }
  }
}
@media (max-width: 640px){
  .section-process {
    h2{
      font-size: 2rem;
    }
  }
  .process-list {
    li{
      width: 100%;
      &:nth-child(4){
        margin-left: 0;
      }
      &:nth-child(5){
        margin-left: 0;
      }
    }
  }
  .process-operation {
    width: 100%;
    display: block;
    .btn{
      font-size: 1.6rem;
      width: 100%;
      max-width: 33.6rem;
      clear: both;
      margin: 0 auto 2rem;
      &:last-child{
        margin: 0 auto 2rem;
      }
    }
  }
  .section-professionals{
    h2{
      font-size: 2.5rem;
    }
    .professionals-list li {
      padding: 0 1rem;
      width: 100%;
      &:nth-child(1),&:nth-child(2),&:nth-child(3){
        padding: 0;
      }
    }
  }
  .section-info .article-list dl {
    width: 100%;
    margin-bottom: 2rem;
    &:nth-child(2n){
      padding: 0 0 0 0;
    }
    &:nth-child(2n+1){
      padding: 0 0 0 0;
    }
  }
  .section-communities .communities-list{
    li{
      width: 50%;
    }
  }
}
@media (max-width: 500px){
  .section-communities {
    h2 {
      font-size: 2.6rem;
    }
  }
  .communities-list{
    li{
      width: 100%;
    }
  }
  .section-professionals{
    h2{
      font-size: 2rem;
    }
    .professionals-list{
      display: block;
    }
  }
}
@media (max-width: 450px){
  .section-process {
    h2{
      font-size: 1.8rem;
    }
  }
  .section-communities {
    h2 {
      font-size: 2.2rem;
    }
  }
}
@media (max-width: 400px){
  .section-professionals{
    h2{
      font-size: 2rem;
    }
  }
  .section-communities {
    h2 {
      font-size: 2rem;
    }
  }
  .section-communities .communities-list{
    li{
      width: 100%;
    }
  }
}