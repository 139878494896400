@import "../../../common/styles/_util/variables";
@import "../../../common/styles/_util/mixins";
.section-banner {
  height: 498px;
  position: relative;
  .banner-list {
    position: relative;
    height: 100%;
    li {
      float: left;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  .prev,.next {
    position: absolute;
    cursor: pointer;
    top: 50%;
    z-index: 9999;
    width: 16px;
    height: 33px;
    background-image: url("../images/sprites_home.png");
    background-repeat: no-repeat;
    margin-top: -16px;
    &:hover {
      transition: all 0.2s ease-in;
      opacity: 0.5;
    }
  }
  .prev {
    left: 3.2rem;
    background-position: -143px -478px;
    &:hover {
      left: 3rem;
    }
  }
  .next {
    &:hover {
      right: 3rem;
    }
    right: 3.2rem;
    background-position: -173px -478px;
  }
  .banner-caption {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
    color: #FFFFFF;
    top: 0;
    left: 0;
    .inner {
      display: table-cell;
      vertical-align: middle;
      background: url("../images/banner_mask.png") center center repeat-x;
    }
    h2 {
      font-size: 3.6rem;
      font-family: "open_sansbold";
      line-height: 5rem;
    }
    p {
      font-size: 1.8rem;
      font-family: "open_sanssemibold";
      line-height: 3rem;
    }
    .btn {
      margin-top: 8rem;
      height: 5.6rem;
      width: 19.2rem;
      background: #ffffff;
      font-family: "open_sansbold";
      &:hover {
        color: #FFFFFF;
        transition: all 0.1s ease-in;
        background: @complement-color;
      }
    }
  }
}

.section-process {
  padding-top: 10rem;
  padding-bottom: 11.2rem;
  background: #FFFFFF;
  .container{
    text-align: center;
  }
  h2 {
    font-size: 3.0rem;
    font-family: "open_sansbold";
    text-align: center;
  }
}

  .process-list {
    overflow: hidden;
    font-size: 0;
    padding-bottom: 6.4rem;
    padding-top: 7rem;
    h3 {
      font-size: 2.0rem;
      font-family: "open_sanssemibold";
      padding-top: 2.4rem;
      line-height: 5rem;
      .icon {
        display: block;
        margin: 0 auto;
      }
    }
    li {
      float: left;
      width: 20%;
      box-sizing: border-box;
      height: 28rem;
      vertical-align: top;
      text-align: center;
      border: solid 1px #FFFFFF;
      border-top: none;
      transition: all 0.2s ease-in;
      position: relative;
      line-height: 2.4rem;
      padding:0 1.5rem;
      .text {
        font-size: 1.4rem;
        color: @gray-color;
      }
      &:last-child {
        margin-right: 0;
      }
      &:before {
        transition: all 0.2s ease-in;
        content: " ";
        position: absolute;
        left: -1px;
        width: 22.4rem;
        border-top: 4px solid #FFFFFF;
      }
      &:hover {
        border-color: #e5e5e5;
        &:before {
          width: 100%;
          border-top-color: @primary-color;
        }
      }
    }
  }
  .process-operation {
    text-align: center;
    width: 53%;
    margin: 0 auto;
    .btn {
      display: block;
      .flex();
      margin-right: 3.2rem;
      padding: 1.3rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:last-child {
        background: #004ea2;
        &:hover {
          background: lighten(#004ea2,6%);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

.section-professionals {
  padding-top: 10rem;
  padding-bottom: 11.2rem;
  background: @gray-light-color;
  h2 {
    font-size: 3rem;
    text-align: center;
    font-family: "open_sansbold";
  }
  .professionals-list {
    width: 100%;
    font-size: 0;
    overflow: hidden;
    li {
      float: left;
      width: 33.333%;
      &:first-child {
        padding-right: 4%;
      }
      &:nth-child(2) {
        padding: 0 2%;
      }
      &:last-child {
        padding-left: 4%;
      }
      &:hover{
        p {
          border-top-color: @primary-color;
        }
      }
      h3 {
        font-size: 2rem;
        line-height: 6rem;
        font-family: "open_sanssemibold";
      }
      p {
        height: auto;
        min-height: 10.4rem;
        padding: 0.6rem 2.4rem;
        overflow: hidden;
        font-size: 1.4rem;
        line-height: 2rem;
        color: @gray-color;
        background: #FFFFFF;
        transition: all 0.3s ease-in;
        border-top: solid 6px #d9d9d9;
      }
    }
  }
}

.section-info {
  padding-top: 10rem;
  padding-bottom: 5rem;
  background: #FFFFFF;
  h2 {
    font-size: 3.0rem;
    text-align: center;
    font-family: "open_sansbold";
    line-height: 4rem;
    margin-bottom: 5rem;
  }
  .article-list {
    .clearfix();
    dl {
      float: left;
      height: auto;
      width: 33.333%;
      &:nth-child(3n+1){
        padding: 0 2rem 5rem 0;
        clear: left;
      }
      &:nth-child(3n+2){
        padding: 0 1rem 5rem 0;
      }
      &:nth-child(3n+3){
        padding:0  0 5rem 2rem;
        clear: right;
      }
    }
    dt {
      font-size: 2rem;
      line-height: 4.8rem;
      border-bottom: solid 1px #e5e5e5;
      margin-bottom: 3rem;
      font-family: 'open_semibold';
      font-weight: bold;
    }
    .type-1 {
      dd {
        .clearfix();
        margin-bottom: 4rem;
        &:last-child{
          margin-bottom: 0;
        }
        h3 {
          font-size: 1.6rem;
          padding-bottom: 0.5rem;
          font-weight: normal;
          overflow: hidden;
          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:link,&:visited {
              color: @black-color;
            }
            &:hover {
              color: @link-hover-color;
            }
          }
        }
        .pic {
          margin-right: 1rem;
          width: 112px;
          height: auto;
          a{
            display: block;
            img {
              display: block;
              width: 100%;
              height: auto;
            }

          }

        }
        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: @gray-color;
          height: 73px;
          overflow: hidden;
        }
      }
      .price{
        display: block;
        font-size: 2.4rem;
        color: @primary-color;
      }
    }
    .type-2 {
      dd {
        width: 100%;
        .clearfix();
        margin-bottom: 2rem;
        .pic {
          margin-right: 1.5rem;
          width: 8.4rem;
          height: 8.4rem;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        >div.pic-right{
          .flex();
        }
        h3 {
          font-size: 1.6rem;
          padding-bottom: 0.5rem;
          font-weight: normal;
          a {
            &:link,&:visited {
              color: @black-color;
            }
            &:hover {
              color: @link-hover-color;
            }
          }
        }
        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: @gray-color;
          height: 6rem;
          overflow: hidden;
        }
      }
    }
    .type-3 {
      dd {
        .clearfix();
        position: relative;
        padding-right: 2.5rem;
        margin-bottom: 4rem;
        &:last-child{
          margin-bottom: 0;
        }
        .pic {
          margin-right: 1rem;
          width: 160px;
          height: auto;
          a{
            display: block;
            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }

        }
        h3 {
          font-size: 1.6rem;
          padding-bottom: 0.5rem;
          font-weight: normal;
          max-height: 4.8rem;
          overflow: hidden;
          a {
            &:link,&:visited {
              color: @black-color;
            }
            &:hover {
              color: @link-hover-color;
            }
          }
        }
        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: @gray-color;
          max-height: 4rem;
          overflow: hidden;
        }
        .price {
          font-size: 2.4rem;
          color: @primary-color;
          position: absolute;
          left: 12.2rem;
          bottom: 0;
        }
      }
    }

    .type-4 {
      height: auto;
      dd {
        height: 6.6rem;
        border-left: solid 0.6rem #d9d9d9;
        display: table;
        width: 100%;
        margin-bottom: 0.8rem;
        &:hover {
          transition: all 0.3s ease-in;
          border-left-color: @primary-color;
          a {
            border-top-color:#d9d9d9;
            border-bottom-color: #d9d9d9;
            border-right-color: #d9d9d9;
          }
        }
        a {
          padding-left: 0.8rem;
          padding-right: 2rem;
          border-top: solid 1px #FFFFFF;
          border-bottom: solid 1px #FFFFFF;
          border-right: solid 1px #FFFFFF;
          display: block;
          height: 100%;
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    .type-5 {
      dd {
        display: table;
        line-height: 2.4rem;
        margin-bottom: 0.8rem;
        width: 100%;
        a {
          display: table-cell;
          position: relative;
          height: 6.6rem;
          vertical-align: middle;
          background: #f6f6f6;
          padding-left: 1rem;
          padding-right: 4.5rem;
          &:hover {
            transition: all 0.2s ease-in;
            background: @primary-color;
            color: #FFFFFF;
          }
          &:after {
            content: "+";
            font-size: 2.4rem;
            line-height: 2.4rem;
            position: absolute;
            right: 1rem;
            top: 50%;
            margin-top: -1.2rem;
          }
        }
      }
    }
    .type-6 {
      dd {
        display: table;
        width: 100%;
        line-height: 2.4rem;
        margin-bottom: 0.8rem;
        a {
          display: table-cell;
          position: relative;
          padding-left: 2rem;
          font-size: 1.4rem;
          height: 6.6rem;
          vertical-align: middle;
          border-bottom: solid 1px #e5e5e5;
          &:before {
            content: "•";
            font-size: 3rem;
            line-height: 3rem;
            position: absolute;
            left: 0;
            top: 0.6rem;
            color: @primary-color;
          }
          &:hover {
            &:before {
              transition: all 0.2s ease-in;
              color: @gray-color;
            }
          }
        }
      }
    }
  }
}

.section-communities {
  background: @gray-light-color;
  padding-bottom: 9.6rem;
  h2 {
    font-weight: bold;
    font-size: 3rem;
    padding-top: 10rem;
    padding-bottom: 7rem;
    text-align: center;
    line-height: 4rem;
  }
  .communities-list {
    font-size: 0;
    text-align: center;
    li {
      float: left;
      position: relative;
      width: 25%;
      height: 15rem;
      cursor: pointer;
      padding: 0.8rem;
      text-align: center;
      .li-cont{
        position: relative;
        height: 14rem;
      }
      .content {
        font-size: 1.4rem;
        opacity: 0;
        position: absolute;
        z-index: 99;
        background: #FFFFFF;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: solid 2px @primary-color;
        transition: all 0.2s ease-in;
        display: table;
        .content-inner {
          padding: 0 38px;
          line-height: 2.4rem;
          display: table-cell;
          vertical-align: middle;
          color: @gray-color;
        }
      }
      h3 {
        height: 100%;
        padding-top: 2rem;
        font-size: 1.6rem;
        font-family: "open_sanssemibold";
        background-color: #FFFFFF;
        .icon {
          display: block;
          margin: 0 auto;
        }
        span{
          display: block;
          line-height: 3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:hover {
        .content {
          opacity: 1;
        }
      }
    }
  }
}
@import "index-responsive";